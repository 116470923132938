<template>
  <div class="ContactPerson">
    <div class="searchNav">
      <!-- <h2>联系人</h2> -->
      <el-button class="button" icon="el-icon-plus" type="primary" style="margin-left: 0"
        @click="handleAdd">新增</el-button>
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input class="input" v-model="queryParam.keyword" placeholder="请输入用户名/姓名/联系方式" clearable
          @keydown.enter.native="getDataList"></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table :data="tableData" style="width: 100%" :max-height="height" v-loading="loading">
        <el-table-column label="头像" width="70">
          <template slot-scope="scope">
            <el-avatar shape="square" :size="30" :src="scope.row.UserAvatarURL"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="SupplieName" label="所属公司" width="200">
        </el-table-column>
        <el-table-column prop="UserName" label="用户名"> </el-table-column>
        <el-table-column prop="RealName" label="姓名"> </el-table-column>
        <el-table-column prop="UserPhone" label="登录手机号"></el-table-column>
        <!-- <el-table-column prop="SexText" label="性别"> </el-table-column>
        <el-table-column prop="BirthdayText" label="出生日期"></el-table-column> -->
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)">编辑</el-button>
            <el-button type="text" @click="handleCapacity(scope.row)">权限</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination layout="total,prev, pager, next,sizes" :total="pagination.total" :current-page="pagination.current"
        :page-size="pagination.pageSize" :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0" @current-change="currentChange"
        @size-change="handleSizeChange">
      </el-pagination>
    </div>
    <EditForm ref="editForm" :parentObj="{ ...this }" />
    <Capacity ref="capacity" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup.vue";
import { toThousands } from "@/utils/Tools";
import EditForm from "./UserManagementForm.vue";
import Capacity from "./UserManagementCapacity.vue";
export default {
  props: {},
  components: {
    EditForm,
    Capacity,
    radioGroup,
  },
  mounted() {
    this.getDataList();
  },
  created() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      queryParam: {
        condition: "cInvName",
        SupplieId: "全部",
        IsSubAccount: true,
      },
      loading: false,
      toThousands,
    };
  },
  methods: {
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      console.log(e);
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        this.$refs.editForm.openForm();
      } else {
        this.$refs.editForm.openForm(null, this.queryParam.SupplieId);
      }
      // this.$refs.editForm.openForm(null);
    },
    handleView(row) {
      this.$refs.editForm.openForm(row.Id);
    },
    handleCapacity(row){
      this.$refs.capacity.openForm(row.Id);
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/Base_Manage/Base_User/GetDataList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.el-menu-item.is-active {
    color: #1aad19;
}
.el-button--primary {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/ .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: #FFF;
    background-color: #1aad19;
    border-color: #1aad19;
}
/deep/.el-radio-button__inner:hover {
    color: #1aad19;
}</style>
